<template>
    <TFGComponent></TFGComponent>
</template>

<script>
    import TFGComponent from '../components/TFGComponent'

    export default {
        name: 'TFGView',
        components: {
            TFGComponent
        }
    }
</script>