<template>
    <div>
        <h1 class="text-center">Tickets For Good</h1>
        <div class="text-center">
            <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
            <div v-if="!loading">
                <div>Event Ticket Limit: {{event.eventTicketLimit}}</div>
                <h2>Tickets</h2>
                <div v-for="t, i in event.tickets" :key="i">
                    <div v-for="o, j in t.offers" :key="j">
                        <v-row>
                            <v-col>
                                <b>{{o.offerName}}</b>
                                <v-icon v-if="o.currency == 'USD' || o.currency == 'CAD'">mdi-currency-usd</v-icon>
                                <v-icon v-if="o.currency == 'GBP'">mdi-currency-gbp</v-icon>
                                <v-icon v-if="o.currency == 'EUR'">mdi-currency-eur</v-icon>
                                {{o.faceValue}}
                            </v-col>
                            <v-col>
                                <div>Ticket Type ID: {{o.ticketTypeId}}</div>
                                <div>Price Level ID: {{o.priceLevelId}}</div>
                                <v-select :label="o.offerName + ' Amount'" :items="o.sellableQuantities" clearable></v-select>
                            </v-col>
                        </v-row>
                    </div>
                    <div>Seating: {{t.seating}}</div>
                    <div>Availability: {{t.available}}</div>
                    <div>Total: {{t.total}}</div>
                    <div>Limit: {{t.currentTicketLimit}}</div>
                    <hr />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'TFGComponent',
        created() {
            this.getEvent()
        },
        data() {
            return {
                loading: true,
                eventFee: 495,
                //1ku8v-paGA5pKnC
                //vvG1GZb5xJKo9m
                url: 'https://app.ticketmaster.com/partners/v1/events/1ku8v-paGA5pKnC/availability?apikey=wfKIM7hxQbNO4Hl9GQF3FGzGvAzO0gpD&source=TM_US&getBasePrice=true'
            }
        },
        methods: {
            getEvent() {
                fetch(this.url, {
                    method: 'GET'
                }).then(response => {
                    response.json().then(res => {
                        this.loading = false
                        this.event = res.event
                        console.log(res.event)
                    })
                })
            }
        }
    }
</script>