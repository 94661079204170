<template>
    <div>
        <h1 class="text-center">Admin</h1>

        <div class="text-center">
            <v-btn color="success" @click="newAdminConfirm">
                <v-icon>
                    mdi-account-plus
                </v-icon>
            </v-btn>
        </div>

        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th v-for="p in pages" :key="p" class="text-center">
                            {{p}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="a in main.admins" :key="a.user">
                        <td>
                            <v-icon v-if="permissions.includes('edit')" small
                                    class="mr-2"
                                    color="primary"
                                    @click="editAdminStartConfirm(a)">
                                mdi-pencil
                            </v-icon>
                            <v-icon v-if="permissions.includes('delete')" small
                                    class="mr-2"
                                    color="error"
                                    @click="deleteAdminConfirm(a)">
                                mdi-delete
                            </v-icon>
                            {{ a.name }}
                        </td>
                        <td>{{ a.user }}</td>
                        <td v-for="p in pages" :key="p" class="text-center">
                            <v-icon v-if="checkUserPagePermission(a, p, 'view')" small
                                    class="mr-2"
                                    color="primary">
                                mdi-eye
                            </v-icon>
                            <v-icon v-if="checkUserPagePermission(a, p, 'edit')" small
                                    class="mr-2"
                                    color="primary">
                                mdi-pencil
                            </v-icon>
                            <v-icon v-if="checkUserPagePermission(a, p, 'delete')" small
                                    class="mr-2"
                                    color="primary">
                                mdi-delete
                            </v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="form.show" width="750">
            <v-card v-if="permissions.includes('edit') && form.show">
                <v-card-title class="text-center text-h5 grey" v-if="form.type == 'Add'">Add New Admin Account</v-card-title>
                <v-card-title class="text-center text-h5 grey" v-if="form.type == 'Edit'">Edit Admin Account</v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col>
                                <v-text-field label="User Email" v-model="form.admin.user" :rules="textRules" :disabled="form.type != 'Add'"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Name" v-model="form.admin.name" :rules="textRules"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-for="p, i in form.admin.pages" :key="i">
                            <v-col>
                                <v-text-field label="Page" v-model="p.name" v-if="p.name" disabled>
                                    <v-icon slot="prepend" color="error" @click="removePage(i)">mdi-delete</v-icon>
                                </v-text-field>
                                <v-select label="Page" :items="unselectedPages" v-model="p.name" v-if="!p.name" :rules="textRules">
                                    <v-icon slot="prepend" color="error" @click="removePage(i)">mdi-delete</v-icon>
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select label="Permissions" :items="permissionOptions" v-model="p.permissions" multiple :rules="selectRules"></v-select>
                            </v-col>
                        </v-row>
                        <div class="text-center">
                            <v-btn color="primary" @click="addPage" :disabled="!unselectedPages.length || form.admin.pages.length >= pages.length">Add Page Access</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="success" @click="addAdmin" v-if="form.show && form.type == 'Add'">
                        Save New
                        <v-progress-circular indeterminate v-if="saving"></v-progress-circular>
                    </v-btn>
                    <v-btn color="success" @click="editAdmin" v-if="form.show && form.type == 'Edit'">
                        Update Account
                        <v-progress-circular indeterminate v-if="saving"></v-progress-circular>
                    </v-btn>
                    <v-btn color="error" @click="form.show = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="generalDialog.show" width="500">
            <v-card>
                <v-card-title class="text-h5 grey">
                    {{generalDialog.title}}
                </v-card-title>

                <v-card-text>
                    {{generalDialog.text}}
                    <div v-html="generalDialog.html"></div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-end">
                    <v-btn v-if="generalDialog.buttonText && generalDialog.buttonType" @click="generalDialogFunction">{{generalDialog.buttonText}}</v-btn>
                    <v-btn color="secondary"
                           @click="generalDialog.show = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.show">
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="pink"
                       text
                       v-bind="attrs"
                       @click="snackbar.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import { db } from '@/stores/db'
    import { doc, getDoc, setDoc } from 'firebase/firestore';

    export default {
        name: 'AdminComponent',
        components: {},
        computed: {
            unselectedPages() {
                let pages = []

                this.pages.forEach(p => {
                    if (!this.form.admin.pages.map(adminP => { return adminP.name }).includes(p))
                        pages.push(p)
                })

                return pages
            }
        },
        async created() {
            if (await this.checkAdmin(this.pageName)) {
                this.permissions = await this.getPermissions(this.pageName)
                if (this.permissions.includes('view'))
                    await this.getAdmins()
            } else
                this.$router.push({ name: 'Home' })
        },
        data() {
            return {
                blankAdmin: {
                    user: '',
                    name: '',
                    pages: [{
                        name: '',
                        permissions: ['view']
                    }]
                },
                blankPage: {
                    name: '',
                    permissions: ['view']
                },
                deleteObject: null,
                editObject: null,
                form: {
                    admin: {
                        user: '',
                        name: '',
                        pages: [{
                            name: '',
                            permissions: ['view']
                        }]
                    },
                    show: false,
                    type: 'Add'
                },
                main: {
                    admins: []
                },
                pages: ['Admin', 'Fitness Account', 'Mahjong', 'Portfolio', 'PortfolioAdmin', 'Recipes', 'RPG'],
                pageName: 'Admin',
                permissions: [],
                permissionOptions: ['view', 'edit', 'delete'],
                saving: false,
                selectRules: [
                    v => v.length > 0 || 'Some permission must be selected'
                ],
                textRules: [
                    v => !!v || 'Field may not be empty'
                ],
                valid: false
            }
        },
        methods: {
            async addAdmin() {
                if (this.validate(true)) {
                    this.saving = true
                    this.showSnackbar('Saving')

                    let mainBackup = JSON.parse(JSON.stringify(this.main))
                    mainBackup.admins.push(JSON.parse(JSON.stringify(this.form.admin)))
                    await this.saveAdmin(mainBackup)

                    this.saving = false
                    this.log({
                        title: 'Added Admin Account',
                        html: `Added Admin Account <b>${this.form.admin.user}</b>.`,
                        text: ''
                    })
                    this.form.show = false
                }
            },
            addPage() {
                this.form.admin.pages.push(JSON.parse(JSON.stringify(this.blankPage)))
            },
            checkUserPagePermission(user, page, permission) {
                let userPage = user.pages.find(p => p.name == page)
                if (userPage)
                    return userPage.permissions.includes(permission)
                else
                    return false
            },
            async deleteAdmin() {
                let index = this.main.admins.indexOf(this.deleteObject)
                if (index > -1) {
                    this.showSnackbar('Deleting Account')

                    let mainBackup = JSON.parse(JSON.stringify(this.main))
                    mainBackup.admins.splice(index, 1)
                    await this.saveAdmin(mainBackup)

                    this.log({
                        title: 'Admin Account Deleted',
                        html: `Admin Account <b>${this.deleteObject.user}</b> has been deleted.`,
                        text: ''
                    })
                    this.form.show = false
                }
            },
            deleteAdminConfirm(a) {
                this.deleteObject = a
                if (a.user != this.$userData.email.toLowerCase())
                    this.generalDialog = {
                        buttonText: 'Delete Account',
                        buttonType: 'deleteAdmin',
                        html: `Delete Admin Account <b>${a.user}</b>?`,
                        show: true,
                        text: '',
                        title: 'Delete Admin Account'
                    }
                else
                    this.generalDialog = {
                        buttonText: '',
                        buttonType: '',
                        html: `Cannot Delete your own Admin Account <b>${a.user}</b>?`,
                        show: true,
                        text: '',
                        title: 'Cannot Delete Admin Account'
                    }
            },
            async editAdmin() {
                let index = this.main.admins.map(a => { return a.user }).indexOf(this.form.admin.user)
                if (index > -1 && this.validate(false)) {
                    this.showSnackbar('Editing Account')

                    let mainBackup = JSON.parse(JSON.stringify(this.main))
                    mainBackup.admins[index] = this.form.admin
                    await this.saveAdmin(mainBackup)

                    let currentUser = this.form.admin.user == this.$userData.email.toLowerCase()
                    if (currentUser) {
                        this.setCookie('admin', JSON.stringify(this.form.admin), .02)
                    }

                    this.log({
                        title: 'Admin Account Updated',
                        html: `Admin Account <b>${this.form.admin.user}</b> has been updated. ${currentUser ? "<br>Reload page to use new permissions." : ""}`,
                        text: ''
                    })
                    this.form.show = false
                    this.showSnackbar('Account Edited')
                }
            },
            editAdminStart() {
                this.form = {
                    admin: JSON.parse(JSON.stringify(this.editObject)),
                    show: true,
                    type: 'Edit'
                }
            },
            editAdminStartConfirm(a) {
                this.editObject = a
                if (!this.form.show)
                    this.editAdminStart()
                else
                    this.generalDialog = {
                        buttonText: 'Edit Account',
                        buttonType: 'editAdminStart',
                        html: `Edit Admin Account <b>${a.user}</b>? </br> <b>You will lose any progress on the current admin account.</b>`,
                        show: true,
                        text: '',
                        title: 'Edit Admin Account'
                    }
            },
            async generalDialogFunction() {
                if (this.generalDialog.buttonType) {
                    this.generalDialog.show = false
                    if (this.generalDialog.buttonType == 'editAdminStart')
                        this.editAdminStart()
                    if (this.generalDialog.buttonType == 'deleteAdmin')
                        await this.deleteAdmin()
                    if (this.generalDialog.buttonType == 'newAdmin')
                        this.newAdmin()
                }
            },
            async getAdmins() {
                const docRef = doc(db, 'admins', 'main')
                const docSnap = await getDoc(docRef)
                this.main = docSnap.data()
                this.main.admins.sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0 })
            },
            log(obj) {
                this.generalDialog = {
                    buttonText: '',
                    buttonType: '',
                    html: obj.html,
                    show: true,
                    text: obj.text,
                    title: obj.title
                }
                obj.time = this.getTime()
                this.logDialog.log.unshift(obj)
            },
            newAdmin() {
                this.form = {
                    admin: JSON.parse(JSON.stringify(this.blankAdmin)),
                    show: true,
                    type: 'Add'
                }
            },
            newAdminConfirm() {
                if (!this.form.show)
                    this.newAdmin()
                else
                    this.generalDialog = {
                        buttonText: 'New Account',
                        buttonType: 'newAdmin',
                        html: `Start New Admin Account? </br> <b>You will lose any progress on the current admin account.</b>`,
                        show: true,
                        text: '',
                        title: 'New Admin Account'
                    }
            },
            removePage(i) {
                this.form.admin.pages.splice(i, 1)
            },
            async saveAdmin(main) {
                await setDoc(doc(db, 'admins', 'main'), main)
                this.main = main
            },
            validate(newAccount) {
                if (this.main.admins.map(a => { return a.user }).includes(this.form.admin.user) && newAccount) {
                    this.log({
                        title: 'Duplicate Account',
                        html: `Admin account already exists with user email <b>${this.form.admin.user}</b>, please edit existing account.`,
                        text: ''
                    })
                    return false
                }
                return this.$refs.form.validate()
            }
        }
    }
</script>