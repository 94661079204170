import Vue from 'vue'
import './css/characterSheet.css'
import './css/recipes.css'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { db } from '@/stores/db'
import { doc, getDoc } from 'firebase/firestore';

Vue.prototype.$signedIn = false;
Vue.prototype.$userData = {};

var signin = localStorage.getItem('signin')
if (signin) {
    var data = JSON.parse(signin)
    Vue.prototype.$signedIn = (data.id && data.email) ? true : false;
    Vue.prototype.$userData = data;
}

Vue.component('CharacterComponent', require('./components/rpg/CharacterComponent.vue').default);

Vue.mixin({
    data() {
        return {
            generalDialog: {
                buttonText: '',
                buttonType: '',
                html: '',
                show: false,
                text: '',
                title: ''
            },
            logDialog: {
                log: [],
                selectedLog: {
                    copyText: '',
                    id: null,
                    object: {},
                    title: '',
                    type: ''
                },
                show: false
            },
            snackbar: {
                show: false,
                text: ''
            }
        }
    },
    methods: {
        async checkAdmin(pageName) {
            if (this.$signedIn) {
                if (!this.getCookie('admin')) {
                    const docRef = doc(db, 'admins', 'main')
                    const docSnap = await getDoc(docRef)
                    let main = docSnap.data()
                    let admin = main.admins.find(a => { return a.user == this.$userData.email.toLowerCase() })
                    if (!admin)
                        admin = { user: this.$userData.email.toLowerCase(), pages: [] }
                    this.setCookie('admin', JSON.stringify(admin), .02)
                }
                let admin = JSON.parse(this.getCookie('admin'))
                if (pageName)
                    return admin.pages.some(p => { return p.name == pageName })
                else
                    return admin.pages.length > 0
            } else
                return false
        },
        determineSuccesses(dieResult) {
            if (dieResult < 4)
                return 0
            if (dieResult < 6)
                return 1
            if (dieResult >= 6)
                return 2
        },
        async getAdminPages() {
            if (await this.checkAdmin(null))
                return JSON.parse(this.getCookie('admin')).pages
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        async getPermissions(pageName) {
            if (await this.checkAdmin(pageName)) {
                let admin = JSON.parse(this.getCookie('admin'))
                let page = admin.pages.find(p => p.name == pageName)
                if (page)
                    return page.permissions
            }
            return []
        },
        getRandomIntInclusive: function (min, max) {
            min = Math.ceil(min)
            max = Math.floor(max)
            return Math.floor(Math.random() * (max - min + 1) + min)
        },
        getTime() {
            var d = new Date();
            var hours = (d.getHours() < 13) ? d.getHours() : (d.getHours() - 12).toString();
            var minutes = ((d.getMinutes() < 10) ? '0' : '') + d.getMinutes().toString();
            var seconds = ((d.getSeconds() < 10) ? '0' : '') + d.getSeconds().toString();
            return hours + ':' + minutes + ':' + seconds;
        },
        rollDice(diceToRoll) {
            let result = {
                diceResults: [],
                successes: 0
            }

            for (var i = 0; i < diceToRoll; i++) {
                var dieResult = this.getRandomIntInclusive(1, 6)
                result.diceResults.push(dieResult)
                result.successes += this.determineSuccesses(dieResult)
            }

            return result
        },
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        showSnackbar(text) {
            this.snackbar = {
                show: true,
                text: text
            }
        }
    }
})

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
    router,
    vuetify,
    render: h => h(App),
    pinia
}).$mount('#app')