<template>
    <RecipesComponent></RecipesComponent>
</template>

<script>
    import RecipesComponent from '../components/recipes/RecipesComponent'

    export default {
        name: 'RecipesView',
        components: {
            RecipesComponent
        }
    }
</script>