<template>
    <div>
        <h1 class="text-center">Recipes</h1>

        <div class="text-center" v-if="permissions.includes('edit')">
            <v-btn color="success" @click="newRecipeConfirm" :disabled="saving">New Recipe</v-btn>
        </div>

        <v-form ref="form" v-model="valid" v-if="permissions.includes('edit') && form.show">
            <v-fab-transition>
                <v-btn color="primary" @click="saveRecipeConfirm" :disabled="saving" fixed bottom left fab>
                    <v-icon v-if="!saving">mdi-content-save</v-icon>
                    <v-progress-circular indeterminate v-if="saving"></v-progress-circular>
                </v-btn>
            </v-fab-transition>
            <h2 class="text-center" v-if="form.type == 'Add'">Add New Recipe</h2>
            <h2 class="text-center" v-if="form.type == 'Edit'">Edit Recipe</h2>
            <v-text-field label="Recipe Name" v-model="form.recipe.name" :rules="textRules"></v-text-field>
            <v-textarea label="Description" v-model="form.recipe.description" auto-grow outlined rows="1"></v-textarea>
            <v-text-field label="Date" type="date" v-model="form.recipe.date" clearable></v-text-field>
            <v-row>
                <v-col cols="6">
                    <v-text-field label="Servings" type="number" v-model="form.recipe.servings"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Serving Calories" type="number" v-model="form.recipe.servingCalories"></v-text-field>
                </v-col>
            </v-row>
            <div class="formSection">
                <h2 class="text-center">Ingredients</h2>
                <v-row v-for="(ingredient, i) in form.recipe.ingredients" :key="i">
                    <v-col cols="12" md="8">
                        <v-text-field label="Ingredient" v-model="ingredient.name" :rules="textRules">
                            <v-icon slot="prepend" color="primary" @click="moveIngredient(i, 'down')">mdi-arrow-down-bold</v-icon>
                            <v-icon slot="prepend" color="primary" @click="moveIngredient(i, 'up')">mdi-arrow-up-bold</v-icon>
                            <v-icon slot="prepend" color="error" @click="deleteIngredientConfirm(i, ingredient)">mdi-delete</v-icon>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                        <v-text-field label="Amount" type="number" v-model="ingredient.amount"></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                        <v-combobox label="Unit" v-model="ingredient.unit" :items="units"></v-combobox>
                    </v-col>
                    <v-col cols="12">
                        <hr v-if="i < (form.recipe.ingredients.length - 1)" />
                    </v-col>
                </v-row>
                <div class="text-center">
                    <v-btn color="primary" @click="addIngredient">Add Ingredient</v-btn>
                </div>
            </div>
            <div class="formSection">
                <h2 class="text-center">Ingredient Subsections</h2>
                <v-row v-for="(sub, i) in form.recipe.ingredientSubsections" :key="i">
                    <v-col cols="12">
                        <v-text-field label="Subsection Name" v-model="sub.name">
                            <v-icon slot="prepend" color="primary" @click="moveSubsection(i, 'down')">mdi-arrow-down-bold</v-icon>
                            <v-icon slot="prepend" color="primary" @click="moveSubsection(i, 'up')">mdi-arrow-up-bold</v-icon>
                            <v-icon slot="prepend" color="error" @click="deleteIngredientSubsectionConfirm(i, sub)">mdi-delete</v-icon>
                        </v-text-field>

                    </v-col>
                    <v-col col="12">
                        <v-row v-for="(ingredient, j) in sub.ingredients" :key="j">
                            <v-col cols="12" md="8">
                                <v-text-field label="Ingredient" v-model="ingredient.name" :rules="textRules">
                                    <v-icon slot="prepend" color="primary" @click="moveSubsectionIngredient(i, j, 'down')">mdi-arrow-down-bold</v-icon>
                                    <v-icon slot="prepend" color="primary" @click="moveSubsectionIngredient(i, j, 'up')">mdi-arrow-up-bold</v-icon>
                                    <v-icon slot="prepend" color="error" @click="deleteIngredientSubsectionIngredientConfirm(i, j, sub, ingredient)">mdi-delete</v-icon>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" md="2">
                                <v-text-field label="Amount" type="number" v-model="ingredient.amount"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="2">
                                <v-combobox label="Unit" v-model="ingredient.unit" :items="units"></v-combobox>
                            </v-col>
                            <v-col cols="12">
                                <hr v-if="j < (sub.ingredients.length - 1)" />
                            </v-col>
                        </v-row>
                        <div class="text-center">
                            <v-btn color="primary" @click="addIngredientToSubsection(i)">Add Ingredient</v-btn>
                        </div>
                    </v-col>
                </v-row>
                <div class="text-center">
                    <v-btn color="primary" @click="addIngredientSubsection">Add Ingredient Subsection</v-btn>
                </div>
            </div>
            <div class="formSection">
                <h2 class="text-center">Instructions</h2>
                <v-row v-for="(instruction, i) in form.recipe.instructions" :key="i">
                    <v-col cols="12">
                        <v-text-field :label="instructionLabel(i)" v-model="instruction.name">
                            <v-icon slot="prepend" color="primary" @click="moveInstruction(i, 'down')">mdi-arrow-down-bold</v-icon>
                            <v-icon slot="prepend" color="primary" @click="moveInstruction(i, 'up')">mdi-arrow-up-bold</v-icon>
                            <v-icon slot="prepend" color="error" @click="deleteInstructionConfirm(i, instruction)">mdi-delete</v-icon>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea label="Text" v-model="instruction.text" auto-grow outlined rows="1" :rules="textRules"></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <hr v-if="i < (form.recipe.instructions.length - 1)" />
                    </v-col>
                </v-row>
                <div class="text-center">
                    <v-btn color="primary" @click="addInstruction">Add Instruction</v-btn>
                </div>
            </div>
            <div>
                <v-row>
                    <v-col cols="6" class="text-center">
                        <v-btn color="success" @click="saveRecipeConfirm" :disabled="saving">
                            <span v-if="form.type == 'Add'">Save New</span>
                            <span v-if="form.type == 'Edit'">Update Recipe</span>
                            <v-progress-circular indeterminate v-if="saving"></v-progress-circular>
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="text-center">
                        <v-btn color="error" @click="form.show = false">
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-form>

        <v-expansion-panels multiple>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h2 class="text-center">Search</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form>
                        <v-autocomplete label="Recipe Name" v-model="searchObj.name" :items="main.recipes.map(x => ({ value: x.name, text: x.name}))" clearable></v-autocomplete>
                        <v-autocomplete label="Includes Ingredients" v-model="searchObj.ingredients" :items="ingredients" multiple clearable></v-autocomplete>
                        <v-text-field label="Includes Text (Minimum 3 Characters)" v-model="searchObj.text"></v-text-field>
                    </v-form>

                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-for="r in recipes" :key="r.name" title="r.name">
                <v-expansion-panel-header>
                    <h1>{{r.name}} {{r.date}}</h1>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-textarea v-model="r.description" v-if="r.description" auto-grow readonly no-resize></v-textarea>
                    <v-row>
                        <v-col cols="6">
                            Servings: {{r.servings}}
                        </v-col>
                        <v-col cols="6">
                            Calories per Serving: {{r.servingCalories}}
                        </v-col>
                    </v-row>
                    <div>
                        <h2>Ingredients</h2>
                        <ul>
                            <li v-for="i in r.ingredients" :key="i.name">
                                <span v-if="i.amount">{{i.amount}}</span> {{i.unit}} {{i.name}}
                            </li>
                        </ul>
                        <v-row v-if="r.ingredientSubsections.length">
                            <v-col cols="12" md="4" v-for="s in r.ingredientSubsections" :key="s.name">
                                <h3>{{s.name}}</h3>
                                <ul>
                                    <li v-for="i in s.ingredients" :key="i.name">
                                        <span v-if="i.amount">{{i.amount}}</span> {{i.unit}} {{i.name}}
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </div>
                    <div>
                        <h2>Instructions</h2>
                        <v-row>
                            <v-col cols="12" md="6" v-for="(i,index) in r.instructions" :key="i.name+index">
                                <h3>{{index + 1}}) {{i.name}}</h3>
                                <v-textarea v-model="i.text" auto-grow readonly no-resize></v-textarea>
                            </v-col>
                        </v-row>
                    </div>
                    <div>
                        <v-row>
                            <v-col class="text-center" v-if="permissions.includes('edit')">
                                <v-btn color="primary" @click="editConfirm(r)">Edit</v-btn>
                            </v-col>
                            <v-col class="text-center" v-if="permissions.includes('delete')">
                                <v-btn color="error" @click="deleteConfirm(r)" :disabled="saving">Delete</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog v-model="generalDialog.show" width="500">
            <v-card>
                <v-card-title class="text-h5 grey">
                    {{generalDialog.title}}
                </v-card-title>

                <v-card-text>
                    {{generalDialog.text}}
                    <div v-html="generalDialog.html"></div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-end">
                    <v-btn v-if="generalDialog.buttonText && generalDialog.buttonType" @click="generalDialogFunction">{{generalDialog.buttonText}}</v-btn>
                    <v-btn color="secondary"
                           @click="generalDialog.show = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.show">
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="pink"
                       text
                       v-bind="attrs"
                       @click="snackbar.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import { db } from '@/stores/db'
    import { doc, getDoc, setDoc } from 'firebase/firestore';
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: 'RecipesComponent',
        async created() {
            if (await this.checkAdmin(this.pageName)) {
                this.permissions = await this.getPermissions(this.pageName)
                if (this.permissions.includes('view'))
                    await this.getRecipes()
            } else
                this.$router.push({ name: 'Home' })
        },
        computed: {
            ingredients() {
                let ingredients = []

                this.recipes.forEach(r => {
                    ingredients = ingredients.concat(r.ingredients.map(x => ({ value: x.name, text: x.name })))
                    r.ingredientSubsections.forEach(is => {
                        ingredients = ingredients.concat(is.ingredients.map(x => ({ value: x.name, text: x.name })))
                    })
                })
                ingredients = [...new Set(ingredients)].sort((a, b) => a.text.localeCompare(b.text))

                return ingredients
            },
            recipes() {
                let recipes = structuredClone(this.main.recipes)

                if (this.searchObj.name && this.searchObj.name.trim().length > 0)
                    recipes = recipes.filter(r => { return r.name == this.searchObj.name })

                if (this.searchObj.ingredients && this.searchObj.ingredients.length > 0) {
                    recipes = recipes.filter(r => {
                        return (
                            this.searchObj.ingredients.every(i => {
                                return r.ingredients.map(x => x.name).includes(i) ||
                                    r.ingredientSubsections.filter(is => {
                                        return is.ingredients.map(x => x.name).includes(i)
                                    }).length > 0
                            })
                        )
                    })
                }

                if (this.searchObj.text && this.searchObj.text.trim().length >= 3)
                    recipes = recipes.filter(r => {
                        return JSON.stringify(r).toLowerCase().includes(this.searchObj.text.toLowerCase())
                    })

                return recipes
            },
        },
        data() {
            return {
                main: {
                    recipes: [],
                },
                blankIngredient: {
                    amount: 0,
                    name: '',
                    unit: '',
                },
                blankInstruction: {
                    name: '',
                    text: '',
                },
                blankRecipe: {
                    date: new Date(new Date().toLocaleDateString()).toISOString().substr(0, 10),
                    description: '',
                    instructions: [{
                        name: '',
                        text: '',
                    }],
                    id: '',
                    ingredients: [{
                        amount: 0,
                        name: '',
                        unit: '',
                    }],
                    name: '',
                    servingCalories: 0,
                    servings: 0,
                    ingredientSubsections: [],
                },
                blankIngredientSubsection: {
                    name: '',
                    ingredients: [{
                        amount: 0,
                        name: '',
                        unit: '',
                    }]
                },
                form: {
                    new: true,
                    recipe: {
                        date: '',
                        description: '',
                        instructions: [],
                        id: '',
                        ingredients: [],
                        name: '',
                        servingCalories: 0,
                        servings: 0,
                        ingredientSubsections: [],
                    },
                    show: false,
                    type: 'Add',
                },
                ingredientIndex: 0,
                ingredientSubsectionIndex: 0,
                instructionIndex: 0,
                pageName: 'Recipes',
                permissions: [],
                recipeToEdit: null,
                saving: false,
                searchObj: {
                    ingredients: [],
                    name: '',
                    text: ''
                },
                textRules: [
                    v => !!v || 'Field may not be empty'
                ],
                units: [
                    'mL',
                    'l',
                    'g',
                    'kg',
                    'tsp',
                    'Tbsp',
                    'C',
                    'pt',
                    'qt',
                    'gal',
                    'oz',
                    'fl oz',
                    'lb'
                ],
                valid: false,
            }
        },
        methods: {
            addIngredient() {
                this.form.recipe.ingredients.push(structuredClone(this.blankIngredient))
            },
            addIngredientToSubsection(i) {
                this.form.recipe.ingredientSubsections[i].ingredients.push(structuredClone(this.blankIngredient))
            },
            addInstruction() {
                this.form.recipe.instructions.push(structuredClone(this.blankInstruction))
            },
            addIngredientSubsection() {
                this.form.recipe.ingredientSubsections.push(structuredClone(this.blankIngredientSubsection))
            },
            async delete() {
                this.saving = true
                this.showSnackbar('Deleting Recipe')
                let mainBackup = structuredClone(this.main)

                let index = mainBackup.recipes.findIndex(x => { return x.id == this.recipeToEdit.id })
                if (index > -1)
                    mainBackup.recipes.splice(index, 1)

                await setDoc(doc(db, 'recipes', 'main'), mainBackup)
                this.main = mainBackup

                this.saving = false
                this.log({
                    title: 'Recipe Deleted',
                    html: `<b>${this.recipeToEdit.name}</b>`,
                    text: ''
                })
                this.showSnackbar('Recipe Deleted')
            },
            deleteConfirm(recipe) {
                this.recipeToEdit = structuredClone(recipe)
                this.generalDialog = {
                    buttonText: 'Delete Recipe',
                    buttonType: 'delete',
                    html: `Delete Recipe <b>${recipe.name}</b>`,
                    show: true,
                    text: '',
                    title: 'Delete Recipe'
                }
            },
            deleteIngredient() {
                this.form.recipe.ingredients.splice(this.ingredientIndex, 1)
            },
            deleteIngredientConfirm(i, ingredient) {
                this.ingredientIndex = i
                this.generalDialog = {
                    buttonText: 'Delete Ingredient',
                    buttonType: 'deleteIngredient',
                    html: `Delete Ingredient <b>${ingredient.name}</b>`,
                    show: true,
                    text: '',
                    title: 'Delete Ingredient'
                }
            },
            deleteIngredientSubsection() {
                this.form.recipe.ingredientSubsections.splice(this.ingredientSubsectionIndex, 1)
            },
            deleteIngredientSubsectionConfirm(i, subsection) {
                this.ingredientSubsectionIndex = i
                this.generalDialog = {
                    buttonText: 'Delete Ingredient Subsection',
                    buttonType: 'deleteIngredientSubsection',
                    html: `Delete Ingredient Subsection <b>${subsection.name}</b>`,
                    show: true,
                    text: "This will remove the subsection and all of it's ingredients",
                    title: 'Delete Ingredient Subsection'
                }
            },
            deleteIngredientSubsectionIngredient() {
                this.form.recipe.ingredientSubsections[this.ingredientSubsectionIndex].ingredients.splice(this.ingredientIndex, 1)
            },
            deleteIngredientSubsectionIngredientConfirm(i, j, sub, ingredient) {
                this.ingredientSubsectionIndex = i
                this.ingredientIndex = j
                this.generalDialog = {
                    buttonText: 'Delete Ingredient',
                    buttonType: 'deleteIngredientSubsectionIngredient',
                    html: `Delete Ingredient <b>${ingredient.name}</b> from Subsection <b>${sub.name}</b>`,
                    show: true,
                    text: '',
                    title: 'Delete Ingredient'
                }
            },
            deleteInstruction() {
                this.form.recipe.instructions.splice(this.instructionIndex, 1)
            },
            deleteInstructionConfirm(i, instruction) {
                this.instructionIndex = i
                this.generalDialog = {
                    buttonText: 'Delete Instruction',
                    buttonType: 'deleteInstruction',
                    html: `Delete Instruction <b>${i + 1}) ${instruction.name}</b>`,
                    show: true,
                    text: '',
                    title: 'Delete Instruction'
                }
            },
            edit() {
                this.form = {
                    recipe: this.recipeToEdit,
                    show: true,
                    type: 'Edit',
                    new: false,
                }
            },
            editConfirm(recipe) {
                this.recipeToEdit = structuredClone(recipe)
                this.generalDialog = {
                    buttonText: 'Edit Recipe',
                    buttonType: 'edit',
                    html: `Edit Recipe <b>${recipe.name}</b>?`,
                    show: true,
                    text: '',
                    title: 'Start Editing Recipe'
                }
            },
            async generalDialogFunction() {
                if (this.generalDialog.buttonType) {
                    this.generalDialog.show = false
                    if (this.generalDialog.buttonType == 'delete')
                        await this.delete()
                    if (this.generalDialog.buttonType == 'deleteIngredient')
                        this.deleteIngredient()
                    if (this.generalDialog.buttonType == 'deleteIngredientSubsection')
                        this.deleteIngredientSubsection()
                    if (this.generalDialog.buttonType == 'deleteIngredientSubsectionIngredient')
                        this.deleteIngredientSubsectionIngredient()
                    if (this.generalDialog.buttonType == 'deleteInstruction')
                        this.deleteInstruction()
                    if (this.generalDialog.buttonType == 'edit')
                        this.edit()
                    if (this.generalDialog.buttonType == 'newRecipe')
                        this.newRecipe()
                    if (this.generalDialog.buttonType == 'saveRecipe')
                        await this.saveRecipe()
                }
            },
            async getRecipes() {
                const docRef = doc(db, 'recipes', 'main')
                const docSnap = await getDoc(docRef)
                this.main = docSnap.data()
            },
            instructionLabel(i) {
                return `${i + 1}) Name`
            },
            log(obj) {
                this.generalDialog = {
                    buttonText: '',
                    buttonType: '',
                    html: obj.html,
                    show: true,
                    text: obj.text,
                    title: obj.title
                }
                obj.time = this.getTime()
                this.logDialog.log.unshift(obj)
            },
            moveIngredient(index, direction) {
                if (!(index == 0 && direction == 'up') && !(index == (+this.form.recipe.ingredients.length - 1) && direction == 'down')) {
                    let targetIndex = (direction == 'up') ? +index - 1 : +index + 1
                    let targetCopy = structuredClone(this.form.recipe.ingredients[targetIndex])
                    this.form.recipe.ingredients[targetIndex] = this.form.recipe.ingredients[index]
                    this.form.recipe.ingredients[index] = targetCopy
                    let ingredientsCopy = structuredClone(this.form.recipe.ingredients)
                    this.form.recipe.ingredients = []
                    this.form.recipe.ingredients = ingredientsCopy
                }
            },
            moveInstruction(index, direction) {
                if (!(index == 0 && direction == 'up') && !(index == (+this.form.recipe.instructions.length - 1) && direction == 'down')) {
                    let targetIndex = (direction == 'up') ? +index - 1 : +index + 1
                    let targetCopy = structuredClone(this.form.recipe.instructions[targetIndex])
                    this.form.recipe.instructions[targetIndex] = this.form.recipe.instructions[index]
                    this.form.recipe.instructions[index] = targetCopy
                    let instructionsCopy = structuredClone(this.form.recipe.instructions)
                    this.form.recipe.instructions = []
                    this.form.recipe.instructions = instructionsCopy
                }
            },
            moveSubsection(index, direction) {
                if (!(index == 0 && direction == 'up') && !(index == (+this.form.recipe.ingredientSubsections.length - 1) && direction == 'down')) {
                    let targetIndex = (direction == 'up') ? +index - 1 : +index + 1
                    let targetCopy = structuredClone(this.form.recipe.ingredientSubsections[targetIndex])
                    this.form.recipe.ingredientSubsections[targetIndex] = this.form.recipe.ingredientSubsections[index]
                    this.form.recipe.ingredientSubsections[index] = targetCopy
                    let ingredientSubsectionsCopy = structuredClone(this.form.recipe.ingredientSubsections)
                    this.form.recipe.ingredientSubsections = []
                    this.form.recipe.ingredientSubsections = ingredientSubsectionsCopy
                }
            },
            moveSubsectionIngredient(index, jndex, direction) {
                if (!(jndex == 0 && direction == 'up') && !(jndex == (+this.form.recipe.ingredientSubsections[index].ingredients.length - 1) && direction == 'down')) {
                    let targetjndex = (direction == 'up') ? +jndex - 1 : +jndex + 1
                    let targetCopy = structuredClone(this.form.recipe.ingredientSubsections[index].ingredients[targetjndex])
                    this.form.recipe.ingredientSubsections[index].ingredients[targetjndex] = this.form.recipe.ingredientSubsections[index].ingredients[jndex]
                    this.form.recipe.ingredientSubsections[index].ingredients[jndex] = targetCopy
                    let ingredientsCopy = structuredClone(this.form.recipe.ingredientSubsections[index].ingredients)
                    this.form.recipe.ingredientSubsections[index].ingredients = []
                    this.form.recipe.ingredientSubsections[index].ingredients = ingredientsCopy
                }
            },
            newRecipeConfirm() {
                if (this.form.show)
                    this.generalDialog = {
                        buttonText: 'New Recipe',
                        buttonType: 'newRecipe',
                        html: '',
                        show: true,
                        text: 'Start new recipe',
                        title: 'New Recipe'
                    }
                else
                    this.newRecipe()
            },
            newRecipe() {
                this.form = {
                    new: true,
                    recipe: structuredClone(this.blankRecipe),
                    show: true,
                    type: 'Add',
                }
            },
            async saveRecipe() {
                if (this.validate()) {
                    this.saving = true
                    this.showSnackbar('Saving')
                    let dialogTitle = ''
                    let mainBackup = structuredClone(this.main)

                    if (this.form.new) {
                        this.form.recipe.id = uuidv4()
                        mainBackup.recipes.push(structuredClone(this.form.recipe))
                        dialogTitle = 'New Recipe Saved'
                    } else {
                        let index = mainBackup.recipes.findIndex(x => { return x.id == this.form.recipe.id })
                        if (index > -1) {
                            mainBackup.recipes[index] = structuredClone(this.form.recipe)
                            dialogTitle = 'Recipe Updated'
                        }
                    }

                    await setDoc(doc(db, 'recipes', 'main'), mainBackup)
                    this.main = mainBackup

                    this.form.new = false
                    this.showSnackbar('Saved')
                    this.saving = false
                    this.log({
                        title: dialogTitle,
                        html: `<b>${this.form.recipe.name}</b>.`,
                        text: ''
                    })
                    this.form.show = false
                }
            },
            saveRecipeConfirm() {
                if (this.validate()) {
                    if (this.form.new)
                        this.generalDialog = {
                            buttonText: 'Save Recipe',
                            buttonType: 'saveRecipe',
                            html: '',
                            show: true,
                            text: '',
                            title: 'Save New Recipe'
                        }
                    else
                        this.generalDialog = {
                            buttonText: 'Update Recipe',
                            buttonType: 'saveRecipe',
                            html: `Update Recipe <b>${this.form.recipe.name}</b>`,
                            show: true,
                            text: '',
                            title: 'Update Recipe'
                        }
                }
            },
            validate() {
                return this.$refs.form.validate()
            },
        }
    }
</script>